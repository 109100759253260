import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PublicKeyApi} from './public-key.api';
import {Observable, switchMap} from 'rxjs';
import {HttpResult, UserToken} from '../entity/http-result';
import {
  aesEncrypt,
  randomAesIV,
  randomAesKey, resolveTempToken,
  resolveUserToken, signSHA256
} from '../utils/security-utils';
import {environment} from '../../environments/environment';
// @ts-ignore
import * as jsrsasign from 'jsrsasign';
import {map} from 'rxjs/operators';
import {TERM_ACCESS_TOKEN, TERM_REFRESH_TOKEN} from '../app-constants';
import {BaseApi} from "./base.api";
import {format, formatISO} from "date-fns";

@Injectable({
  providedIn: 'root'
})
export class PassportApi extends BaseApi {
  constructor(private http: HttpClient, private publicKeyApi: PublicKeyApi) {
    super();
  }

  logout(): Observable<any> {
    return this.httpResultMap(this.http.get<HttpResult<any>>(`${environment.BUSHEZHOUYE_API}/v2/logout`));
  }

  login(phoneAreaNum: string, phoneNum: string, password: string): Observable<UserToken> {

    // 先获取一个公钥
    return this.publicKeyApi.getPublicKey().pipe(switchMap((publicKey: string) => {
      // 本地生成一串随机字符串，用作aes加密的key
      const aesKey = randomAesKey();
      const aesIv = randomAesIV();

      const rsaKey = jsrsasign.KEYUTIL.getKey(publicKey);

      const aesKeyHex = jsrsasign.KJUR.crypto.Cipher.encrypt(aesKey, rsaKey);
      const aesIvHex = jsrsasign.KJUR.crypto.Cipher.encrypt(aesIv, rsaKey);

      const phoneNumHex = aesEncrypt(`${phoneAreaNum}${phoneNum}`, aesKey, aesIv);
      const passwdHex = aesEncrypt(password, aesKey, aesIv);

      const timestamp = formatISO(new Date());
      const timestampHex = aesEncrypt(timestamp, aesKey, aesIv);
      const signRaw = `${phoneAreaNum}${phoneNum}${password}${aesKey}${aesIv}${timestamp}`;
      const signResult = signSHA256(signRaw)
      const signHex = aesEncrypt(signResult, aesKey, aesIv);


      const hexBody = `${phoneNumHex}o${passwdHex}o${aesKeyHex}o${aesIvHex}o${timestampHex}o${signHex}`;

      return this.http.post<HttpResult<string>>(`${environment.BUSHEZHOUYE_API}/v3/user-login/phone-num-passwd`, hexBody).pipe(map((result: HttpResult<string>) => {
        if (HttpResult.succeed(result.code)) {
          return resolveUserToken(result.data!, rsaKey, aesKey, aesIv);
        } else {
          throw new Error(result.message);
        }
      }));

    }));
  }

  finishSignUp(passwd: string, nickName: string, aesKey: string, aesIv: string, rsaKey: any): Observable<UserToken> {
    const aesKeyHex = jsrsasign.KJUR.crypto.Cipher.encrypt(aesKey, rsaKey);
    const aesIvHex = jsrsasign.KJUR.crypto.Cipher.encrypt(aesIv, rsaKey);

    const passwdHex = aesEncrypt(passwd, aesKey, aesIv);

    const nickNameBytes = jsrsasign.CryptoJS.enc.Utf8.parse(nickName);
    const nickNameHex = jsrsasign.CryptoJS.enc.Hex.stringify(nickNameBytes);

    const hexBody = `${passwdHex}o${nickNameHex}o${aesKeyHex}o${aesIvHex}`;

    return new Observable<UserToken>(subscriber => {
      return this.http.post<HttpResult<string>>(`${environment.BUSHEZHOUYE_API}/v3/finish-sign-up/password`, hexBody).subscribe({
        next: (result: HttpResult<string>) => {
          if (HttpResult.succeed(result.code)) {
            const userToken = resolveUserToken(result.data!, rsaKey, aesKey, aesIv);
            subscriber.next(userToken);
          } else {
            subscriber.error(new Error(result.message));
          }
        },
        error: err => {
          subscriber.error(err);
        }
      });
    });

  }

  signUpSms(phoneAreaNum: string, phoneNum: string, smsCode: string, passwd: string, nickname: string,
            rsaKey: any): Observable<UserToken> {
    // 本地生成一串随机字符串，用作aes加密的key
    const aesKey = randomAesKey();
    const aesIv = randomAesIV();

    const aesKeyHex = jsrsasign.KJUR.crypto.Cipher.encrypt(aesKey, rsaKey);
    const aesIvHex = jsrsasign.KJUR.crypto.Cipher.encrypt(aesIv, rsaKey);

    const phoneAreaNumHex = aesEncrypt(phoneAreaNum, aesKey, aesIv);
    const phoneNumHex = aesEncrypt(phoneNum, aesKey, aesIv);
    const smsCodeHex = aesEncrypt(smsCode, aesKey, aesIv);

    const timestamp = formatISO(new Date());
    const timestampHex = aesEncrypt(timestamp, aesKey, aesIv);
    const signRaw = `${phoneAreaNum}${phoneNum}${smsCode}${aesKey}${aesIv}${timestamp}`;
    const signResult = signSHA256(signRaw)
    const signHex = aesEncrypt(signResult, aesKey, aesIv);

    const hexBody = `${phoneAreaNumHex}o${phoneNumHex}o${smsCodeHex}o${aesKeyHex}o${aesIvHex}o${timestampHex}o${signHex}`;

    return new Observable<UserToken>(subscriber => {
      this.http.post<HttpResult<string>>(`${environment.BUSHEZHOUYE_API}/v3/sign-up/sms`, hexBody).subscribe({
        next: (result: HttpResult<string>) => {
          if (HttpResult.succeed(result.code)) {

            const tempToken = resolveTempToken(result.data!, rsaKey, aesKey, aesIv);
            localStorage.setItem(TERM_ACCESS_TOKEN, tempToken);

            this.finishSignUp(passwd, nickname, aesKey, aesIv, rsaKey).subscribe({
              next: (token) => {
                subscriber.next(token);
              },
              error: err => {
                subscriber.error(err);
              }
            });
          } else {
            subscriber.error(new Error(result.message));
          }
        },
        error: err => {
          subscriber.error(err);
        }
      })
    });

  }

  signUp(phoneAreaNum: string, phoneNum: string, passwd: string, smsCode: string, nickname: string): Observable<UserToken> {
    // return this.publicKeyApi.getPublicKey().pipe(switchMap((publicKey: PublicKey) => {
    //
    //   const rsaKey = jsrsasign.KEYUTIL.getKey(publicKey.key);
    //
    //   return this.signUpPhoneNum(phoneAreaNum, phoneNum, smsCode, passwd, nickname, rsaKey, publicKey.num)
    // }));

    return new Observable(subscriber => {
      this.publicKeyApi.getPublicKey().subscribe({
        next: (publicKey) => {
          const rsaKey = jsrsasign.KEYUTIL.getKey(publicKey);
          this.signUpSms(phoneAreaNum, phoneNum, smsCode, passwd, nickname, rsaKey).subscribe({
            next: (token) => {
              subscriber.next(token);
            },
            error: err => {
              subscriber.error(err);
            }
          });
        },
        error: err => {
          subscriber.error(err);
        }
      });
    });
  }

  forgetPasswdSms(phoneAreaNum: string, phoneNum: string, smsCode: string, passwd: string,
                  rsaKey: any): Observable<UserToken> {

    // 本地生成一串随机字符串，用作aes加密的key
    const aesKey = randomAesKey();
    const aesIv = randomAesIV();

    const aesKeyHex = jsrsasign.KJUR.crypto.Cipher.encrypt(aesKey, rsaKey);
    const aesIvHex = jsrsasign.KJUR.crypto.Cipher.encrypt(aesIv, rsaKey);


    const phoneAreaNumHex = aesEncrypt(phoneAreaNum, aesKey, aesIv);
    const phoneNumHex = aesEncrypt(phoneNum, aesKey, aesIv);
    const smsCodeHex = aesEncrypt(smsCode, aesKey, aesIv);

    const timestamp = formatISO(new Date());
    const timestampHex = aesEncrypt(timestamp, aesKey, aesIv);
    const signRaw = `${phoneAreaNum}${phoneNum}${smsCode}${aesKey}${aesIv}${timestamp}`;
    const signResult = signSHA256(signRaw)
    const signHex = aesEncrypt(signResult, aesKey, aesIv);

    const hexBody = `${phoneAreaNumHex}o${phoneNumHex}o${smsCodeHex}o${aesKeyHex}o${aesIvHex}o${timestampHex}o${signHex}`;

    return new Observable<UserToken>(subscriber => {
      this.http.post<HttpResult<string>>(`${environment.BUSHEZHOUYE_API}/v3/forget-password/sms`, hexBody).subscribe({
        next: (result: HttpResult<string>) => {
          if (HttpResult.succeed(result.code)) {

            const tempToken = resolveTempToken(result.data!, rsaKey, aesKey, aesIv);
            localStorage.setItem(TERM_ACCESS_TOKEN, tempToken);

            this.finishForgetPasswd(passwd, aesKey, aesIv, rsaKey).subscribe({
              next: (token) => {
                subscriber.next(token);
              },
              error: err => {
                subscriber.error(err);
              }
            });
          } else {
            subscriber.error(new Error(result.message));
          }
        },
        error: err => {
          subscriber.error(err);
        }
      })
    });
  }

  finishForgetPasswd(passwd: string, aesKey: string, aesIv: string, rsaKey: any): Observable<UserToken> {
    const aesKeyHex = jsrsasign.KJUR.crypto.Cipher.encrypt(aesKey, rsaKey);
    const aesIvHex = jsrsasign.KJUR.crypto.Cipher.encrypt(aesIv, rsaKey);

    const passwdHex = aesEncrypt(passwd, aesKey, aesIv);

    const hexBody = `${passwdHex}o${aesKeyHex}o${aesIvHex}`;

    return new Observable<UserToken>(subscriber => {
      return this.http.post<HttpResult<string>>(`${environment.BUSHEZHOUYE_API}/v3/finish-forget-password/password`, hexBody).subscribe({
        next: (result: HttpResult<string>) => {
          if (HttpResult.succeed(result.code)) {
            const userToken = resolveUserToken(result.data!, rsaKey, aesKey, aesIv);
            subscriber.next(userToken);
          } else {
            subscriber.error(new Error(result.message));
          }
        },
        error: err => {
          subscriber.error(err);
        }
      });
    });

  }

  forgetPasswd(phoneAreaNum: string, phoneNum: string, passwd: string, smsCode: string): Observable<any> {
    return new Observable(subscriber => {
      this.publicKeyApi.getPublicKey().subscribe({
        next: (publicKey) => {
          const rsaKey = jsrsasign.KEYUTIL.getKey(publicKey);
          this.forgetPasswdSms(phoneAreaNum, phoneNum, smsCode, passwd, rsaKey).subscribe({
            next: (token) => {
              subscriber.next(token);
            },
            error: err => {
              subscriber.error(err);
            }
          });
        },
        error: err => {
          subscriber.error(err);
        }
      });
    });
  }

}
